import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
	UserType,
	permission,
} from "@sportyano/core/models/permissions/permission";
import { ToggleTimeslotResponse } from "@sportyano/core/models/programs/time-slot-toggle.model";
import {
	ITimeSlotRequest,
	TimeSlotResponse,
} from "@sportyano/core/models/programs/time-slots.model";
import { CommonService } from "@sportyano/core/services/shard/common.service";
import { IPutResponse, IResponse } from "@sportyano/core/models/response/response.interface";
import { IProgramsData } from "@sportyano/core/models/programs/program-data.interface";
import { IPlayground } from "@sportyano/core/models/playgrounds/playground.interface";
import { IPlaygroundReservedResponse } from "@sportyano/shared/components/custom-calendar/models/const/reserved-response";
import {
	IBookingRequest,
	IBookingResponse,
} from "@sportyano/main-site/booking/booking/models/interfaces/booking.interface";

@Injectable({
	providedIn: "root",
})
export class ProgramsScheduleService {
	constructor(
		private http: HttpClient,
		private commonService: CommonService
	) {}

	createProgram(program: any, userType: UserType): Observable<any> {
		if (userType === permission.academy) {
			return this.http.post(
				`${environment.baseUrl}academies/programs`,
				program
			);
		} else {
			return this.http.post(
				`${environment.baseUrl}playgrounds/grounds`,
				program
			);
		}
	}

	getAllPrograms(page: number, userType: UserType) {
		if (userType === permission.academy) {
			return this.http.get(
				`${environment.baseUrl}academies/programs?page=${page}`
			) as Observable<IResponse<IProgramsData<any>>>;
		} else {
			return this.http.get(
				`${environment.baseUrl}playgrounds/grounds?page=${page}`
			) as Observable<IResponse<IProgramsData<IPlayground>>>;
		}
	}

	getAllProgramsPending(page: number, userType: UserType) {
		if (userType === permission.academy) {
			return this.http.get(
				`${environment.baseUrl}pending-programs?page=${page}`
			) as Observable<IResponse<IProgramsData<any>>>;
		} else {
			return this.http.get(
				`${environment.baseUrl}pending-grounds?page=${page}`
			) as Observable<any>;
		}
	}


	getPendingProgramById(programId: string, userType: UserType): Observable<any> {
		if (userType === permission.academy) {
			const url = `${environment.baseUrl}pending-programs/${programId}`;
			return this.http.get<any>(url);
		} else {
			const url = `${environment.baseUrl}pending-grounds/${programId}`;
			return this.http.get<any>(url);
		}
	}

	updateProgramStatus(
		programId: string,
		status: boolean,
		userType: UserType
	): Observable<any> {
		if (userType === permission.academy) {
			const url = `${environment.baseUrl}academies/programs/${programId}/update-status`;
			return this.http.patch(url, status);
		} else {
			const url = `${environment.baseUrl}playgrounds/${programId}/update-status`;
			return this.http.patch(url, status);
		}
	}

	updateProgram(
		programId: string,
		program: any,
		userType: UserType
	): Observable<any> {
		if (userType === permission.academy) {
			const url = `${environment.baseUrl}academies/programs/${programId}`;
			return this.http.put(url, program);
		} else {
			const url = `${environment.baseUrl}playgrounds/grounds/${programId}`;
			return this.http.put(url, program);
		}
	}
	updateProgramPending(
		programId: string,
		program: any,
		userType: UserType
	): Observable<any> {
		if (userType === permission.academy) {
			const url = `${environment.baseUrl}pending-programs/${programId}`;
			return this.http.put(url, program);
		} else {
			const url = `${environment.baseUrl}pending-grounds/${programId}`;
			return this.http.put(url, program);
		}
	}
	createTimeSlot(
		programId: string,
		timeSlotData: any,
		userType: UserType,
		timeSlotId: number
	): Observable<any> {
		if (userType === permission.academy) {
			const url =
				`${environment.baseUrl}academies/programs/${programId}/time-slots` +
				(timeSlotId ? `/${timeSlotId}` : "");
			return this.http.post(url, timeSlotData);
		} else {
			const url = `${environment.baseUrl}playgrounds/${programId}/time-slots?id=${timeSlotId}`;
			return this.http.post(url, timeSlotData);
		}
	}

	public updateProgramSlots(
		programId: string,
		slots: Array<ITimeSlotRequest>
	) {
		const url = `${environment.baseUrl}academies/programs/${programId}/time-slots`;
		return this.http.post(url, { slots: slots });
	}

	getTimeSlots(
		programId: string,
		userType: UserType
	): Observable<TimeSlotResponse> {
		if (userType === permission.academy) {
			const url = `${environment.baseUrl}academies/programs/${programId}/time-slots`;
			return this.http.get<TimeSlotResponse>(url);
		} else {
			const url = `${environment.baseUrl}playgrounds/programs/${programId}/time-slots`;
			return this.http.get<TimeSlotResponse>(url);
		}
	}

	createProgramPicture(file: FormData) {
		return this.commonService.uploadFile(file);
	}

	createProgramStartingDates(
		programId: string,
		startingDate: string,
		userType: UserType
	) {
		if (userType === permission.academy) {
			const url = `${environment.baseUrl}academies/programs/${programId}/start-date`;
			return this.http.post(url, {
				start_date: startingDate,
			});
		} else {
			return;
		}
	}

	toggleProgramTimeSlot(
		programId: string,
		timeslotId: number
	): Observable<ToggleTimeslotResponse> {
		const url = `${environment.baseUrl}academies/programs/${programId}/time-slots/${timeslotId}`;
		return this.http.put<ToggleTimeslotResponse>(url, {});
	}

	deleteProgramTimeSlot(
		programId: string,
		timeslotId: number
	): Observable<any> {
		const url = `${environment.baseUrl}academies/programs/${programId}/time-slots/${timeslotId}`;
		return this.http.delete<ToggleTimeslotResponse>(url, {});
	}

	programBooking(
		programId: string,
		payload: IBookingRequest
	): Observable<IBookingResponse> {
		const url = `${environment.baseUrl}academies/programs/${programId}/booking`;
		return this.http.post<IBookingResponse>(url, payload);
	}

	playgroundBooking(
		programId: string,
		payload: IBookingRequest
	): Observable<IBookingResponse> {
		const url = `${environment.baseUrl}playgrounds/${programId}/booking`;
		return this.http.post<IBookingResponse>(url, payload);
	}

	public applyProgramDiscount(payload: {
		programId: number;
		discount: number;
	}): Observable<IPutResponse> {
		const url = `${environment.baseUrl}academies/programs/${payload.programId}/discount`;
		return this.http.put<IPutResponse>(url, payload);
	}
}
